import React, { useState, useEffect } from "react";
import "./App.css";
import "@aws-amplify/ui-react/styles.css";
import {
  Button,
  Flex,
  Heading,
  Text,
  TextField,
  View,
  withAuthenticator,
  Image,
} from "@aws-amplify/ui-react";

/**
import { listNotes } from "./graphql/queries";
import {
  createNote as createNoteMutation,
  deleteNote as deleteNoteMutation,
} from "./graphql/mutations";
import { API, Storage } from "aws-amplify";
 */

// replacing API
import { generateClient } from 'aws-amplify/api';
import { createNote as createNoteMutation,
  updateNote as updateNoteMutation,
  deleteNote as deleteNoteMutation } from './graphql/mutations';
import { listNotes } from './graphql/queries';
// replacing Storage
import { getUrl, uploadData, remove } from 'aws-amplify/storage';

const client = generateClient();

const App = ({ signOut }) => {
  const [notes, setNotes] = useState([]);

  useEffect(() => {
    fetchNotes();
  }, []);

  async function fetchNotes() {
    // const apiData = await API.graphql({ query: listNotes });
    const apiData = await client.graphql({ query: listNotes });

    const notesFromAPI = apiData.data.listNotes.items;
    await Promise.all(
      notesFromAPI.map(async (note) => {
        if (note.image) {
          // const url = await Storage.get(note.name);
          const url = await getUrl(note.name); // https://docs.amplify.aws/nextjs/build-a-backend/storage/storage-v5-to-v6-migration-guide/
          note.image = url;
        }
        return note;
      })
    );
    setNotes(notesFromAPI);
  }

  async function createNote(event) {
    console.log("1")
    event.preventDefault();
    console.log("2")
    const form = new FormData(event.target);
    console.log("3")
    const image = form.get("image");
    console.log("4")
    const data = {
      name: form.get("name"),
      description: form.get("description"),
      // image: image.name,
    };
    console.log("5")
    console.log(data)
    // if (!!data.image) await Storage.put(data.name, image);
    // https://docs.amplify.aws/javascript/build-a-backend/storage/upload/
    if (!!data.image) {
      console.log("we in boys")
      try {
        const result  = await uploadData(data.name, image).result;
        console.log('Succeeded: ', result);
      } catch (error) {
        console.log('Error : ', error);
      }
    }
    console.log("6")

    // await API.graphql({
    //   query: createNoteMutation,
    //   variables: { input: data },
    // });
    
    await client.graphql({
      query: createNoteMutation,
      variables: {
        input: data
      }
    }).then(response => {
      // Handle successful response here
      console.log(response);
    }).catch(error => {
      // Handle error here
      console.error('GraphQL request failed:', error);
    });
    console.log("7")
    console.log("8")
    fetchNotes();
    event.target.reset();
  }

  async function deleteNote({ id, name }) {
    const newNotes = notes.filter((note) => note.id !== id);
    setNotes(newNotes);

    //await Storage.remove(name);
    try {
      await remove(name);
    } catch (error) {
      console.log('Error ', error);
    }

    // await API.graphql({
    //   query: deleteNoteMutation,
    //   variables: { input: { id } },
    // });
    await client.graphql({
      query: deleteNoteMutation,
      variables: { input: { id } }
    });
  }

  return (
    <View className="App">
      <Heading level={1}>My Notes App</Heading>
      <View as="form" margin="3rem 0" onSubmit={createNote}>
        <Flex direction="row" justifyContent="center">
          <TextField
            name="name"
            placeholder="Note Name"
            label="Note Name"
            labelHidden
            variation="quiet"
            required
          />
          <TextField
            name="description"
            placeholder="Note Description"
            label="Note Description"
            labelHidden
            variation="quiet"
            required
          />
          <View
            name="image"
            as="input"
            type="file"
            style={{ alignSelf: "end" }}
          />
          <Button type="submit" variation="primary">
            Create Note
          </Button>
        </Flex>
      </View>
      <Heading level={2}>Current Notes</Heading>
      <View margin="3rem 0">
        {notes.map((note) => (
          <Flex
            key={note.id || note.name}
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Text as="strong" fontWeight={700}>
              {note.name}
            </Text>
            <Text as="span">{note.description}</Text>
            {note.image && (
              <Image
                src={note.image}
                alt={`visual aid for ${note.name}`}
                style={{ width: 400 }}
              />
            )}
            <Button variation="link" onClick={() => deleteNote(note)}>
              Delete note
            </Button>
          </Flex>
        ))}
      </View>
      <Button onClick={signOut}>Sign Out</Button>
    </View>
  );
};

export default withAuthenticator(App);